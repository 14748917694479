<template>
  <b-card class="mobile-card" header-bg-variant="light">
    <b-container>
      <b-row align-v="center">
        <b-col>
          <strong>Campagne {{ item.campagne }}</strong>
          <br />
          Edit&eacute; le {{ item.dateValeur }}
        </b-col>
        <b-col cols="auto">
          <DownloadComponent
            :typeDocument="typeDocument"
            :targetId="item.idFichier"
          ></DownloadComponent>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import DownloadComponent from "../controls/DownloadComponent.vue";
export default {
  name: "RecapitulatifEgalimCard",
  components: { DownloadComponent },
  props: ["item"],
  data() {
    return {
      typeDocument: this.$store.state.expl.typesDocument.RECAP_EGALIM,
    };
  },
};
</script>
