<template>
  <section>
    <TitleBar>Prix fin de campagne</TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <StrapiEditoComponent
        :societe="exploitationCourante.codeSociete"
      ></StrapiEditoComponent>
      <template v-if="fichiers == null">
        <div class="text-center text-warning my-3">
          <b-spinner class="align-middle"></b-spinner>
          <br />
          <strong>Chargement en cours...</strong>
        </div>
      </template>
      <template v-else-if="fichiers.length === 0">
        <BubbleComponent>
          <p>
            <strong>
              Vous n'avez aucun r&eacute;capitulatif de prix fin de campagne.
            </strong>
          </p>
          <p>Plusieurs explications sont possibles :</p>
          <ul>
            <li>
              Votre coop&eacute;rative n'a pas encore effectu&eacute; la
              1&egrave;re &eacute;dition
            </li>
            <li>Vous n'avez effectu&eacute; aucun apport pour le moment</li>
          </ul>
          <p>
            <em>
              Si vous pensez qu'il y a erreur, vous pouvez bien entendu toujours
              contacter votre coop&eacute;rative !
            </em>
          </p>
        </BubbleComponent>
      </template>
      <template v-else-if="affichageMobile">
        <RecapitulatifEgalimCard
          v-for="item in fichiersParCampagne"
          :key="`fichier-${item.idFichier}`"
          :item="item"
        ></RecapitulatifEgalimCard
      ></template>
      <template v-else>
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <strong>Campagne</strong>
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <strong>Date d'&eacute;dition</strong>
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <strong>Document</strong>
          </b-col>
        </b-row>
        <b-row
          v-for="item in fichiersParCampagne"
          :key="`fichier-${item.idFichier}`"
        >
          <b-col cols="12" md="4" lg="3">{{ item.campagne }}</b-col>
          <b-col cols="12" md="4" lg="3">{{ item.dateValeur }}</b-col>
          <b-col cols="12" md="4" lg="3">
            <DownloadComponent
              :typeDocument="typeDocument"
              :targetId="item.idFichier"
            ></DownloadComponent>
          </b-col>
        </b-row>
      </template>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import StrapiEditoComponent from "../../components/strapi/StrapiEditoComponent.vue";
import BubbleComponent from "../../components/BubbleComponent.vue";
import RecapitulatifEgalimCard from "../../components/cards/RecapitulatifEgalimCard.vue";
import DownloadComponent from "../../components/controls/DownloadComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import UtilsService, { SortDirection } from "../../services/utils.service";
export default {
  name: "PrixFinCampagneView",
  components: {
    TitleBar,
    ErrorComponent,
    StrapiEditoComponent,
    BubbleComponent,
    RecapitulatifEgalimCard,
    DownloadComponent,
  },
  data() {
    return {
      typeDocument: this.$store.state.expl.typesDocument.RECAP_EGALIM,
      error: null,
      fichiers: null,
    };
  },
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    affichageMobile() {
      return this.$store.getters["affichageMobile"];
    },
    campagnes() {
      return this.fichiers
        ? [...new Set(this.fichiers.map((f) => f.campagne))]
        : [];
    },
    fichiersParCampagne() {
      return this.campagnes.map((c) =>
        this.fichiers.find((f) => f.campagne === c)
      );
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.error = null;
      try {
        let response = await ExploitationService.getRecapitulatifsEgalim(
          this.exploitationCourante.codeTiers
        );
        this.fichiers = response.data
          .map((f) => {
            let dateEdition = f.cheminFichier.split("_")[2];
            f.dateValeur =
              dateEdition.slice(6, 8) +
              "/" +
              dateEdition.slice(4, 6) +
              "/" +
              dateEdition.slice(0, 4);
            f.campagne = parseInt(dateEdition.slice(0, 4)) - 1;
            return f;
          })
          .sort(
            UtilsService.sortByStringProperty(
              "cheminFichier",
              SortDirection.DESC
            )
          );
      } catch (error) {
        this.error = UtilsService.handleError(error);
      }
    },
  },
};
</script>
